import { Facebook } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { Fragment, useState } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import { useDispatch } from "react-redux";
import {
  useGetUserProfileMutation,
  useGetUserTokenFBMutation,
  useGetUserTokenGoogleMutation,
} from "../apis/user";
import { homeActions } from "../store/store";
import FeedbackDialog from "../utils/FeedbackDialog";
import LoadingDialog from "../utils/LoadingDialog";

const LoginDialog = ({ onClose, open }) => {
  //#region STYLES
  //#endregion

  //#region HOOKS & APIS

  //const classes = useStyles();
  //const history = useHistory();
  //const navigate = useNavigate();
  const dispatch = useDispatch();

  //USER TOKEN FB
  const [
    getUserTokenFB,
    {
      data: fbTokenData,
      error: fbTokenDataError,
      isLoading: isLoadingFbTokenData,
    },
  ] = useGetUserTokenFBMutation();

  //USER TOKEN FB
  const [
    getUserTokenGoogle,
    {
      data: googleTokenData,
      error: googleTokenDataError,
      isLoading: isLoadingGoogleTokenData,
    },
  ] = useGetUserTokenGoogleMutation();

  //USER PROFILE
  const [
    getUserProfile,
    {
      data: userProfileData,
      error: userProfileDataError,
      isLoading: isLoadingUserProfileData,
    },
  ] = useGetUserProfileMutation();

  //#endregion

  //#region UTIL

  const onLoginDialogActionBtnClick = () => {
    onClose();
  };

  const responseFacebook = (response) => {
    //console.log("fb");

    if (response.accessToken) {
      setTokenAPIStateIndex(1); //GET FB USER TOKEN
      getUserTokenFB(response.accessToken);
    } else {
      setTokenAPIStateIndex(4);
    }
  };

  const responseGoogle = (response) => {
    //console.log(response);

    if (response.tokenId) {
      setTokenAPIStateIndex(2); //GET GOOGLE USER TOKEN
      getUserTokenGoogle(response.tokenId);
    } else {
      setTokenAPIStateIndex(4);
    }
  };

  const termsCond_BtnClick = () => {
    //dispatch(homeActions.setActiveGameUrl(`https://imigames.s3.ap-southeast-1.amazonaws.com/html_info/imi_app/terms_and_conditions/index.html?platform=pwa`));
    //history.push(ImiPathsUrls.game);

    //history.push(ImiPathsUrls.subs);
    //document.getElementById("gameIframe").src += '';

    console.log("Clicked");
  };

  //#endregion

  //#region USER TOKEN APIS STATES

  const [tokenAPIStateIndex, setTokenAPIStateIndex] = useState(0);

  let tokenAPIStateContent;

  //FB TOKEN
  if (tokenAPIStateIndex === 1) {
    if (isLoadingFbTokenData) {
      tokenAPIStateContent = <LoadingDialog />;
    } else if (fbTokenDataError) {
      console.log(fbTokenDataError);

      tokenAPIStateContent = (
        <FeedbackDialog
          id="FB Login Error Dialog"
          open={true}
          onClose={() => {
            setTokenAPIStateIndex(0);
          }}
          data={{
            title: "Oops!",
            msg: "FB Error",
          }}
        />
      );
    } else if (fbTokenData) {
      console.log(fbTokenData);

      dispatch(homeActions.setUserToken(fbTokenData.access_token));
      localStorage.setItem("token", fbTokenData.access_token);
      setTokenAPIStateIndex(5);

      //setTokenAPIStateIndex(3);
      //getUserProfile(fbTokenData.access_token);
    }
  }
  //GOOGLE TOKEN
  else if (tokenAPIStateIndex === 2) {
    if (isLoadingGoogleTokenData) {
      tokenAPIStateContent = <LoadingDialog />;
    } else if (googleTokenDataError) {
      //console.log(googleTokenDataError.error);

      tokenAPIStateContent = (
        <FeedbackDialog
          id="Google Login Error Dialog"
          open={true}
          onClose={() => {
            setTokenAPIStateIndex(0);
          }}
          data={{
            title: "Oops!",
            msg: "Google Error",
          }}
        />
      );
    } else if (googleTokenData) {
      //console.log(googleTokenData);

      //token.current = fbTokenData.access_token;
      //localStorage.setItem("token", fbTokenData.access_token);
      setTokenAPIStateIndex(5);
    }
  }
  //USER TOKEN
  else if (tokenAPIStateIndex === 3) {
    if (isLoadingUserProfileData) {
      tokenAPIStateContent = <LoadingDialog />;
    } else if (userProfileDataError) {
      console.log(userProfileDataError);
      tokenAPIStateContent = (
        <FeedbackDialog
          id="Token Error Dialog"
          open={true}
          onClose={() => {
            setTokenAPIStateIndex(0);
          }}
          data={{
            title: "Oops!",
            msg: "Error",
          }}
        />
      );
    } else if (userProfileData) {
      console.log(userProfileData);
      dispatch(
        homeActions.setUserData({
          name: userProfileData.name,
          image: userProfileData.image_url,
        })
      );
      setTokenAPIStateIndex(0);
      console.log("SUCCESS");
      onClose();
    }
  }
  //FEEDBACK DIALOG
  else if (tokenAPIStateIndex === 4) {
    tokenAPIStateContent = (
      <FeedbackDialog
        id="Error Dialog"
        open={true}
        onClose={() => {
          setTokenAPIStateIndex(0);
        }}
        data={{
          title: "Oops!",
          msg: "User Cancelled",
        }}
      />
    );
  } else if (tokenAPIStateIndex === 5) {
    tokenAPIStateContent = null;
  }
  //LOGIN DIALOG
  else {
    tokenAPIStateContent = (
      <Fragment>
        <Dialog
          fullWidth={true}
          open={open}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">
            <Typography fontSize="2vh" textAlign="left" fontWeight="100">
              Please login to continue!
            </Typography>
          </DialogTitle>
          <DialogContent
            dividers
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <FacebookLogin
              appId="421341401639640"
              autoLoad={false}
              callback={responseFacebook}
              render={(renderProps) => (
                <Button
                  onClick={renderProps.onClick}
                  style={{
                    backgroundColor: "rgba(75, 111, 167,255)",
                    width: "90%",
                    height: "5vh",
                    marginTop: "1vh",
                  }}
                  startIcon={<Facebook />}
                >
                  <Typography
                    color="white"
                    fontSize="2vh"
                    fontWeight="bold"
                    textTransform="none"
                  >{`Facebook`}</Typography>
                </Button>
              )}
            />

            {/* <GoogleLogin
              clientId="356024293196-6c6skl9e3uf806vslj0h47u5uujp36ee.apps.googleusercontent.com"
              autoLoad={false}
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              render={(renderProps) => (
                <Button
                  onClick={renderProps.onClick}
                  style={{
                    backgroundColor: "rgba(214, 72, 34,255)",
                    width: "90%",
                    height: "5vh",
                    marginTop: "1vh",
                  }}
                  startIcon={<Facebook />}
                >
                  <Typography
                    color="white"
                    fontSize="2vh"
                    fontWeight="bold"
                    textTransform="none"
                  >{`Google`}</Typography>
                </Button>
              )}

              //cookiePolicy={'single_host_origin'}
            /> */}

            <div style={{ height: "2vh" }}></div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Typography
                textTransform="none"
                fontSize="1.4vh"
                textAlign="center"
                fontWeight="500"
              >
                By Logging in, you agree to our&nbsp;
                <span
                  style={{ color: "rgba(255,190,100,255)" }}
                  onClick={termsCond_BtnClick}
                >
                  Terms and Conditions{" "}
                </span>
                and acknowledge that you have read the
                <span
                  style={{ color: "rgba(255,190,100,255)" }}
                  onClick={termsCond_BtnClick}
                >
                  {" "}
                  Privacy Policy.
                </span>
              </Typography>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => onLoginDialogActionBtnClick(false)}
              color="primary"
            >
              <Typography textAlign="center" fontSize="2vh">
                Cancel
              </Typography>
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
  //#endregion

  return <Fragment>{tokenAPIStateContent}</Fragment>;
};

export default LoginDialog;
