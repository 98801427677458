import { LoginRounded } from "@mui/icons-material";
import { Fragment, useRef, useState } from "react";
import { useEffect } from "react";
import GameFrame from "./components/GameFrame";
import LoginDialog from "./components/LoginDialog";
import LoadingDialog from "./utils/LoadingDialog";

function App() {
  //#region HOOKS
  const [openLoginDialog, setOpenLoginDialog] = useState(false);

  useEffect(() => {
    //console.log("CALLED");
    window.addEventListener(
      "message",
      (event) => {
        if (typeof event.data === "string") {
          //CHECK FOR JSON OBJECT
          try {
            let commandObj = JSON.parse(event.data);

            if (commandObj.command === "HIDE_NAV") {
              setOpenLoginDialog(true);
            } else if (commandObj.command === "EXIT") {
            } else if (commandObj.command === "SUBSCRIBE") {
            } else if (commandObj.command === "LOGIN") {
              console.log("Here");
            }
          } catch (e) {
            //console.log("JSON Parse Error");
          }
        }
      },
      false
    );
  }, []);
  //#endregion

  //#region LOGIN DIALOG

  let loginDialogContent = (
    <LoginDialog
      onClose={() => {
        setOpenLoginDialog(false);
      }}
      open={openLoginDialog}
    ></LoginDialog>
  );
  //#endregion

  return (
    <Fragment>
      <GameFrame></GameFrame>
      {loginDialogContent}
    </Fragment>
  );
}

export default App;
