import { configureStore, createSlice } from "@reduxjs/toolkit";
import subscriptionApi from "../apis/subscription";
import userApi from "../apis/user";

const homeSlice = createSlice({
  name: "home",
  initialState: {
    count: 1,
    userToken: null,
  },
  reducers: {
    increase: (state) => {
      state.count++;
    },
    decrease: (state) => {
      state.count--;
    },
    setUserToken: (state, action) => {
      state.userToken = action.payload;
    },
  },
});

//remove feedBackApi!!
const store = configureStore({
  reducer: {
    home: homeSlice.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [subscriptionApi.reducerPath]: subscriptionApi.reducer,
  },
  //CACHING WILL BE HANDLED BY THE MIDDLEWARE
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(userApi.middleware)
      .concat(subscriptionApi.middleware),
});

export const homeActions = homeSlice.actions;

export default store;
