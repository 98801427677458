import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { homeActions } from "../store/store";

const GameFrame = ({ token }) => {
  const userToken = useSelector((state) => state.home.userToken);
  const dispatch = useDispatch();

  useEffect(() => {
    //RETRIEVE DATA ON PAGE REFRESH
    if (userToken === null) {
      let _token = localStorage.getItem("token");
      console.log(_token);

      if (_token) {
        dispatch(homeActions.setUserToken(_token));
      }
    }
  }, []);

  let url = "game/index.html?platform=pwa&version=200";

  if (userToken) {
    url += `&user=${userToken}`;
  }

  console.log(url);
  return (
    <iframe
      id="gameIframe"
      title="gameFrame"
      //src="game/index.html?user=eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJLYWxhbmEiLCJhdXRob3JpdGllcyI6WyJST0xFX0RFViJdLCJpYXQiOjE2MjAzNzcxODEsImV4cCI6MTY1MTkxMzE4MSwidWQiOiI1ZDFjOTU5OS1mY2M3LTQ2ZDMtYmQ2YS1hZjhiMmJmNjQxOWUiLCJ0eXBlIjoiQUNDRVNTIn0.gsQbBX3ALI9dKSD2qMDcw18-z-AQu_WHvBjxqHVdzxM&amp;language=ENGLISH&amp;platform=pwa&amp;isWebview=false"
      src={url}
      allow="microphone; accelerometer; encrypted-media"
      style={{
        width: "100%",
        height: "100%",
        border: "0",
        position: "fixed",
        top: "0",
        left: "0",
        overflow: "hidden",
      }}
    ></iframe>
  );
};

export default GameFrame;
